import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../theme/utils';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { Popover } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    width: `calc(100% - ${theme.spacing.regular * 2}px)`,
    height: `calc(100% - ${theme.spacing.regular * 2}px)`,
    margin: theme.spacing.regular,
    overflow: 'hidden',
    backgroundColor: theme.colors.application.background.default,
  },
  iframeWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'calc(100% + 50px)',
    transform: 'translateY(-50px)',
    overflow: 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    transition: 'transform 0.3s ease',
  },
  floatingShortcut: {
    position: 'absolute',
    bottom: theme.spacing.regular,
    right: theme.spacing.regular,
    zIndex: 10,
    color: theme.colors.application.typography.muted,
    padding: theme.spacing.small,
    borderRadius: theme.spacing.small,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: theme.spacing.small,
    '&:hover': {
      color: theme.colors.application.typography.muted,
    },
  },
  floatingShortcutLeft: {
    position: 'absolute',
    bottom: theme.spacing.regular,
    left: theme.spacing.regular,
    zIndex: 10,
    color: theme.colors.application.typography.muted,
    padding: theme.spacing.small,
    borderRadius: theme.spacing.small,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.small,
    fontSize: '26px',
    '&:hover': {
      color: theme.colors.application.typography.muted,
    },
  },
  infoIcon: {
    fontSize: 20, // Increase size for better visibility
    cursor: 'pointer',
    color: '#6c757d',
  },
  externalLinkIcon: {
    cursor: 'pointer',
    color: '#6c757d',
  },
}));

interface ProjectPlannerIFrameProps {
  className?: string;
  projectId: string;
}

export const ProjectPlannerIFrame: React.FC<ProjectPlannerIFrameProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, projectId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isWideIframe, setIsWideIframe] = useState(true);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const plannerUrl = activeProject
    ? `https://tasks.office.com/${activeProject.eMailSuffix}/de-DE/Home/Planner/#/plantaskboard?groupId=${activeProject.groupId}&planId=${activeProject.masterPlanId}`
    : '';

  const updateIframeWidth = () => {
    if (iframeRef.current) {
      const iframeWidth = iframeRef.current.offsetWidth;
      if (iframeWidth <= 1025) {
        setIsWideIframe(false);
      }
    }
  };

  const openInNewWindow = () => {
    if (plannerUrl) {
      window.open(plannerUrl, '_blank', 'noopener,noreferrer');
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    updateIframeWidth();
    window.addEventListener('resize', updateIframeWidth);
    return () => window.removeEventListener('resize', updateIframeWidth);
  }, []);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      {plannerUrl ? (
        <>
          <div
            className={classes.iframeWrapper}
            style={{
              transform: isWideIframe
                ? 'translate(-291px, -50px)'
                : 'translate(0, -50px)',
              width: isWideIframe ? 'calc(100% + 291px)' : '100%',
            }}
          >
            <iframe
              ref={iframeRef}
              src={plannerUrl}
              className={classes.iframe}
              title={t('projects:planner.iframeTitle', 'Planner')}
              loading="lazy"
            />
          </div>
          <div className={classes.floatingShortcutLeft}>
            <Popover
              trigger="click"
              title={t('projects:planner.infoTitle')}
              overlayStyle={{ maxWidth: '300px' }}
              content={
                <div>
                  <p>{t('projects:planner.loginInfo')}</p>
                </div>
              }
            >
              <FontAwesomeIcon
                icon={['fal', 'circle-info']}
                className={classes.infoIcon}
                title={t('projects:planner.infoTitle', 'Information')}
              />
            </Popover>
          </div>
          <div className={classes.floatingShortcut} onClick={openInNewWindow}>
            <FontAwesomeIcon
              icon={['fal', 'external-link-alt']}
              className={classes.externalLinkIcon}
              title={t('projects:planner.openNewWindow')}
            />
            {t('projects:planner.openNewWindow')}
          </div>
        </>
      ) : (
        <div>{t('projects:planner.noProject')}</div>
      )}
    </div>
  );
};

export default ProjectPlannerIFrame;
