import React, { useEffect, useState, useCallback } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import {
  apiFetchCompanyClassifications,
  apiUpdateCompanyClassification,
  apiDeleteCompanyClassification,
} from '../api';
import { CompanyClassificationDto } from '../../../models/CompanyClassifications';
import CompanyClassificationDrawer from './CompanyClassificationDrawer';
import CompanyClassificationTable from './CompanyClassificationTable';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  saveBar: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#f5f5f5',
    borderTop: '1px solid #ddd',
  },
}));

type CompanyClassificationWithDirty = CompanyClassificationDto & {
  isDirty?: boolean;
};

interface CompanyClassificationPageProps {
  className?: string;
}

const CompanyClassificationPage: React.FC<
  CompanyClassificationPageProps
> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [companyClassifications, setCompanyClassifications] = useState<
    CompanyClassificationWithDirty[]
  >([]);
  const [originalClassifications, setOriginalClassifications] = useState<
    CompanyClassificationDto[]
  >([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Fetch data
  const fetchCompanyClassifications = useCallback(async () => {
    try {
      const { data } = await apiFetchCompanyClassifications();
      data.sort((a, b) => a.name.localeCompare(b.name));
      setCompanyClassifications(
        data.map((item) => ({ ...item, isDirty: false }))
      );
      setOriginalClassifications(data);
    } catch (error) {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.fetchError'),
      });
    }
  }, [t]);

  useEffect(() => {
    fetchCompanyClassifications();
  }, [fetchCompanyClassifications]);

  const handleClassificationChange = (
    updatedClassifications: CompanyClassificationDto[]
  ) => {
    const hasChanges = updatedClassifications.some((item, index) => {
      const original = originalClassifications[index];
      return JSON.stringify(item) !== JSON.stringify(original);
    });

    setCompanyClassifications(updatedClassifications);
    setIsSaveEnabled(hasChanges);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const dirtyEntries = companyClassifications.filter((item) => item.isDirty);

    try {
      await Promise.all(
        dirtyEntries.map(async (item) => {
          const { result } = await apiUpdateCompanyClassification(item);
          if (result.status < 200 || result.status >= 300) {
            throw new Error();
          }
        })
      );

      fetchCompanyClassifications();
      setIsSaveEnabled(false);
    } catch {
      notification.error({
        message: t('common:error'),
        description: t('settings:errorMessages.updateError'),
      });
    }
    setIsSaving(false);
  };

  const handleDeleteClassification = async (name: string) => {
    try {
      await apiDeleteCompanyClassification([name]);
      fetchCompanyClassifications();
    } catch {
      notification.error({
        message: t('common:error'),
        description: t('settings:errorMessages.deleteError'),
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerBar}>
        <Button type="primary" onClick={() => setIsDrawerVisible(true)}>
          {t('common:add')}
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <CompanyClassificationTable
          companyClassifications={companyClassifications}
          deleteCompanyClassification={handleDeleteClassification}
          setUpdatedClassifications={(updated) =>
            handleClassificationChange(updated)
          }
        />
      </div>

      <div className={classes.saveBar}>
        <Button
          type="primary"
          disabled={!isSaveEnabled || isSaving}
          onClick={handleSave}
        >
          {t('common:save')}
        </Button>
      </div>

      <CompanyClassificationDrawer
        visible={isDrawerVisible}
        closeDrawer={() => {
          setIsDrawerVisible(false);
          fetchCompanyClassifications();
        }}
      />
    </div>
  );
};

export default CompanyClassificationPage;
