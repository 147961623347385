import React, {
  useState,
  ReactNode,
  Reducer,
  useReducer,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Table, Select, Switch, Typography, Modal, AutoComplete } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';

import { ContactPicker } from '../../contacts/components/ContactPicker';
import {
  InternalProjectContact,
  InternalProjectContactItem,
} from '../../../models/ProjectContacts';
import { useDispatch, useSelector } from 'react-redux';
import {
  createInternalProjectContact,
  updateProjectContacts,
  fetchInternalProjectContacts,
  updateInternalProjectContact,
} from '../actions';
import {
  ProjectId,
  ProjectRole,
  ContactId,
  InternalProjectContactId,
} from '../../../models/Types';
import Flex from '../../../components/Flex';
import {
  getAllHourlyRates,
  RootReducerState,
  getHourlyRatesIsFetching,
} from '../../../apps/main/rootReducer';
import { HourlyRate } from '../../../models/HourlyRate';
import { tableTranslations } from '../../../util/table';
import classNames from 'classnames';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';
import { useQuery } from '@tanstack/react-query';
import { apiFetchInternalJobTitleSuggestions } from '../../settings/api';
const useStyles = makePrioStyles((theme) => ({
  rootsRoot: {
    overflow: 'hidden',
    height: '100%',
  },
  root: {
    overflow: 'hidden',
    maxHeight: '100%',
    '& .ant-table-row .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-sticky-holder': {
      zIndex: 0,
    },
    '& .ant-table-sticky-scroll': {
      display: 'none',
    },
    '& .ant-spin-nested-loading': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-spin-container': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table-container': {
      overflow: 'hidden',
      height: '100%',
    },
  },
  dropDown: {
    width: '100%',
    '& .ant-select-selection-item': {
      color: theme.old.palette.primaryColor,
    },
  },
  dropDownDisabled: {
    '& .ant-select-selection-item': {
      opacity: 0.5,
    },
  },
  contactPicker: {
    width: '100%',
  },
  footer: {
    padding: theme.old.spacing.unit(2),
  },
  disabledButton: {
    '&.ant-btn:not(.ant-btn-primary) > .svg-inline--fa': {
      opacity: 0.5,
    },
  },
  addedRow: {
    background: theme.old.palette.backgroundPalette.sub,
    '& > td': {
      background: theme.old.palette.backgroundPalette.sub,
    },
  },
  row: {
    '& > td:nth-child(8) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(8) > button': {
      visibility: 'visible',
    },
  },
  secondaryFont: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.extraSmall,
    paddingBottom: theme.spacing.regular,
  },
}));

interface InternalProjectContactsTableProps {
  className?: string;
  contactItems: InternalProjectContactItem[];
  projectId: ProjectId;
  loading?: boolean;
  isSubproject?: boolean;
  isCreatingContext?: boolean;
}

interface TableEntry {
  key: string;
  name: string;
  lastName: string;
  projectRoles: ProjectRole[];
  jobTitle: string;
  hourlyRateId: string;
  emailNotificationEnabled: boolean;
  addUserRow: boolean;
  isTemporary: boolean;
}

interface NewProjectContact {
  contactId?: ContactId;
  projectId: ProjectId;
  projectRoles: ProjectRole[];
  jobTitle: string;
  hourlyRateId: string;
  emailNotificationEnabled: boolean;
  isValid: boolean;
  isAddToAllSubprojects: boolean;
}

type UpdateNewProjectContactKey =
  | 'contactId'
  | 'projectRoles'
  | 'jobTitle'
  | 'hourlyRateId'
  | 'emailNotificationEnabled'
  | 'isAddToAllSubprojects';

type UpdateNewProjectContactValue = string | boolean;

interface UpdateNewProjectContactAction {
  key: UpdateNewProjectContactKey;
  value: UpdateNewProjectContactValue;
}

export const InternalProjectContactsTable: React.FC<
  InternalProjectContactsTableProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    contactItems: propsContactItems,
    projectId,
    loading,
    isSubproject,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [addUserActive, setNewProjectContactRowActive] = useState(false);
  const [contactItems, setContactItems] =
    useState<InternalProjectContactItem[]>(propsContactItems);
  const [originalContactItems, setOriginalContactItems] =
    useState<InternalProjectContactItem[]>(propsContactItems);
  const [modalDeleteContactVisible, setModalDeleteContactVisible] =
    useState<boolean>(false);
  const [contactToDelete, setContactToDelete] = useState<string>(null);

  const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
    getAllHourlyRates(state, projectId)
  );
  const [isLoading, setIsLoading] = useState(false);

  const isHourlyRatesFetching = useSelector(getHourlyRatesIsFetching);

  const { data: __internalJobTitles } = useQuery({
    queryKey: ['projectJobTitleSuggestionDto'],
    queryFn: apiFetchInternalJobTitleSuggestions,
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });

  const jobTitleSuggestions = __internalJobTitles?.data ?? [];

  const emptyNewProjectContact: NewProjectContact = {
    projectId,
    projectRoles: ['projectMember'],
    jobTitle: '',
    hourlyRateId: '',
    emailNotificationEnabled: false,
    isValid: false,
    isAddToAllSubprojects: true,
  };

  const tableBottomRef = useRef(null);

  const isDirty = !equals(originalContactItems, contactItems);
  //#endregion

  //#region ------------------------------ Reducer
  const validate: (state: NewProjectContact) => boolean = (
    state: NewProjectContact
  ) =>
    !!state.contactId &&
    state.projectRoles.length > 0 &&
    state.jobTitle !== '' &&
    state.hourlyRateId !== '';

  const [newProjectContact, updateNewProjectContact] = useReducer<
    Reducer<NewProjectContact, UpdateNewProjectContactAction | 'clear'>
  >(
    (
      state: NewProjectContact,
      action: UpdateNewProjectContactAction | 'clear'
    ) => {
      if (action === 'clear') {
        return emptyNewProjectContact;
      }
      const updatedState = { ...state, [action.key]: action.value };
      return {
        ...updatedState,
        isValid: validate(updatedState),
      };
    },
    emptyNewProjectContact
  );
  //#endregion

  //#region ------------------------------ Columns / TableData
  const updateValue = useCallback(
    (
      internalProjectContactId: string,
      key: UpdateNewProjectContactKey,
      value: UpdateNewProjectContactValue
    ) => {
      const updatedContactItems = contactItems.map((cI) =>
        cI.internalProjectContact.internalProjectContactId ===
        internalProjectContactId
          ? {
              ...cI,
              internalProjectContact: {
                ...cI.internalProjectContact,
                [key]: value,
              },
            }
          : cI
      );
      setContactItems(updatedContactItems);
    },
    [contactItems]
  );

  const renderHourlyRatesSelect = useCallback(
    (value: string, record: TableEntry) => {
      const valueExistsInList = hourlyRates.find(
        (hourlyRate) => value === hourlyRate.hourlyRateId
      );
      return (
        <Select
          bordered={true}
          disabled={record.isTemporary || (!record.addUserRow && addUserActive)}
          value={
            record.addUserRow
              ? newProjectContact.hourlyRateId
              : !valueExistsInList
              ? 'value-not-exists'
              : value
          }
          onChange={(value) =>
            record.addUserRow
              ? updateNewProjectContact({ key: 'hourlyRateId', value })
              : updateValue(record.key, 'hourlyRateId', value)
          }
          className={classNames(classes.dropDown, {
            [classes.dropDownDisabled]: !record.addUserRow && addUserActive,
          })}
          loading={isHourlyRatesFetching}
        >
          {record.addUserRow && (
            <Select.Option value="" disabled>
              {t('projects:projectContactsTable.columnTitles.hourlyRate')}
            </Select.Option>
          )}
          {!valueExistsInList && (
            <Select.Option value="value-not-exists" disabled>
              <Typography.Text type="warning">
                {t('projects:errorMessages.hourlyRateDoesNotExist')}
              </Typography.Text>
            </Select.Option>
          )}
          {hourlyRates.map((hourlyRate) => (
            <Select.Option
              key={hourlyRate.hourlyRateId}
              value={hourlyRate.hourlyRateId}
              label={hourlyRate.name}
            >
              {hourlyRate.name}
            </Select.Option>
          ))}
        </Select>
      );
    },
    [
      addUserActive,
      classes,
      hourlyRates,
      newProjectContact,
      isHourlyRatesFetching,
      t,
      updateValue,
    ]
  );

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('projects:projectContactsTable.columnTitles.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      fixed: 'left',
      sorter: (a, b) => a.lastName?.localeCompare(b.lastName),
      render: (value, record: TableEntry, index: number) =>
        record.addUserRow ? (
          <>
            <ContactPicker
              className={classes.contactPicker}
              value={newProjectContact.contactId}
              onChange={(contactId) =>
                updateNewProjectContact({
                  key: 'contactId',
                  value: contactId as string,
                })
              }
              excludedContactIds={contactItems.map(
                (cI) => cI.contact.contactId
              )}
              contactType="InternalContact"
            />
            <div ref={tableBottomRef}></div>
          </>
        ) : (
          <span style={{ opacity: addUserActive && 0.5 }}>{value}</span>
        ),
      width: 200,
    },
    {
      title: t('projects:projectContactsTable.columnTitles.projectRoles'),
      dataIndex: 'projectRoles',
      filters: [
        {
          text: t(
            'projects:projectContactsTable.columns.projectRoles.projectAdmin'
          ),
          value: 'projectAdmin',
        },
        {
          text: t(
            'projects:projectContactsTable.columns.projectRoles.projectMember'
          ),
          value: 'projectMember',
        },
        {
          text: t(
            'projects:projectContactsTable.columns.projectRoles.projectAssistance'
          ),
          value: 'projectAssistance',
        },
        {
          text: t(
            'projects:projectContactsTable.columns.projectRoles.projectController'
          ),
          value: 'projectController',
        },
      ],
      onFilter: (value, record) =>
        record.projectRoles?.includes(value as ProjectRole) ?? false,
      sorter: (a, b) =>
        a.projectRoles?.join(',')?.localeCompare(b.projectRoles?.join(',')) ??
        0,
      //render: (value, record, index) => (
      render: (value, record) => (
        <Select
          id="internalContactProjectRoleSelector"
          mode="multiple"
          bordered={true}
          disabled={
            record.isTemporary ||
            (!record.addUserRow && addUserActive) ||
            isSubproject
          }
          value={record.addUserRow ? newProjectContact.projectRoles : value}
          onChange={(value) =>
            record.addUserRow
              ? updateNewProjectContact({ key: 'projectRoles', value })
              : updateValue(record.key, 'projectRoles', value)
          }
          className={classes.dropDown}
          optionFilterProp="label"
        >
          {record.addUserRow && (
            <Select.Option value="" disabled>
              {t('projects:projectContactsTable.columns.projectRoles.empty')}
            </Select.Option>
          )}
          <Select.Option
            value="projectAdmin"
            label={t(
              'projects:projectContactsTable.columns.projectRoles.projectAdmin'
            )}
          >
            {t(
              'projects:projectContactsTable.columns.projectRoles.projectAdmin'
            )}
          </Select.Option>
          <Select.Option
            value="projectMember"
            label={t(
              'projects:projectContactsTable.columns.projectRoles.projectMember'
            )}
            disabled={record.addUserRow || value?.includes('projectMember')}
          >
            {t(
              'projects:projectContactsTable.columns.projectRoles.projectMember'
            )}
          </Select.Option>
          <Select.Option
            value="projectAssistance"
            label={t(
              'projects:projectContactsTable.columns.projectRoles.projectAssistance'
            )}
          >
            {t(
              'projects:projectContactsTable.columns.projectRoles.projectAssistance'
            )}
          </Select.Option>
          <Select.Option
            value="projectController"
            label={t(
              'projects:projectContactsTable.columns.projectRoles.projectController'
            )}
          >
            {t(
              'projects:projectContactsTable.columns.projectRoles.projectController'
            )}
          </Select.Option>
        </Select>
      ),
      width: 200,
    },
    {
      title: t('projects:projectContactsTable.columnTitles.jobTitle'),
      dataIndex: 'jobTitle',
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
      render: (value, record) => (
        <AutoComplete
          style={{ width: '100%' }}
          options={
            Array.from(
              new Set(jobTitleSuggestions.map((x) => x.name)) // Remove duplicates
            ).map((name) => ({ value: name })) // Map unique names back to options
          }
          placeholder={t('projects:projectContactsTable.columnTitles.jobTitle')}
          value={record.addUserRow ? newProjectContact.jobTitle : value}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().includes(inputValue.toUpperCase())
          }
          onChange={(value) => {
            record.addUserRow
              ? updateNewProjectContact({
                  key: 'jobTitle',
                  value: value,
                })
              : updateValue(record.key, 'jobTitle', value);
          }}
        />
      ),
      width: 200,
    },
    {
      title: t('projects:projectContactsTable.columnTitles.hourlyRate'),
      dataIndex: 'hourlyRateId',
      sorter: (a, b) => a.hourlyRateId.localeCompare(b.jobTitle),
      render: renderHourlyRatesSelect,
      width: 200,
    },
    {
      title: t(
        'projects:projectContactsTable.columnTitles.emailNotificationEnabled'
      ),
      dataIndex: 'emailNotificationEnabled',
      sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
      render: (value, record) => (
        <Switch
          checked={
            record.addUserRow
              ? newProjectContact.emailNotificationEnabled
              : value
          }
          disabled={record.isTemporary || (!record.addUserRow && addUserActive)}
          onChange={(value) =>
            record.addUserRow
              ? updateNewProjectContact({
                  key: 'emailNotificationEnabled',
                  value,
                })
              : updateValue(record.key, 'emailNotificationEnabled', value)
          }
        />
      ),
      width: 100,
    },
    {
      render: (_, record) =>
        isSubproject ? null : record.addUserRow ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div className={classes.secondaryFont}>
                {t('projects:projectContactsTable.addAllSubprojects')}
              </div>
              <Switch
                checked={newProjectContact.isAddToAllSubprojects}
                onChange={(value) =>
                  updateNewProjectContact({
                    key: 'isAddToAllSubprojects',
                    value,
                  })
                }
              />
            </div>
            <Button
              disabled={!newProjectContact.isValid}
              onClick={saveNewProjectContact}
              iconProp={['fal', 'plus']}
            />
          </div>
        ) : (
          <Button
            onClick={() => handleDelete(record.key)}
            className={classNames(
              'internal-project-contacts-table-delete-button',
              {
                [classes.disabledButton]: addUserActive,
              }
            )}
            disabled={
              record.isTemporary || (!record.addUserRow && addUserActive)
            }
            iconProp={['fal', 'trash']}
            type="link"
          ></Button>
        ),
      width: isSubproject ? 0 : 180,
    },
  ];

  const handleDelete = (key: string) => {
    setModalDeleteContactVisible(true);
    setContactToDelete(key);
  };

  const data: TableEntry[] = contactItems.map(
    (contactItem: InternalProjectContactItem, index: number) => ({
      key: contactItem.internalProjectContact.internalProjectContactId,
      name: contactItem.contact.firstName + ' ' + contactItem.contact.lastName,
      lastName: contactItem.contact.lastName,
      projectRoles: contactItem.internalProjectContact.projectRoles,
      jobTitle: contactItem.internalProjectContact.jobTitle,
      hourlyRateId: contactItem.internalProjectContact.hourlyRateId,
      emailNotificationEnabled:
        contactItem.internalProjectContact.emailNotificationEnabled,
      isTemporary: contactItem.internalProjectContact.isTemporary ?? false,
      addUserRow: false,
    })
  );

  const rowSelection: TableRowSelection<TableEntry> = {
    fixed: true,
    renderCell: (
      value: boolean,
      record: TableEntry,
      index: number,
      originNode: ReactNode
    ) =>
      record.addUserRow ? (
        <FontAwesomeIcon
          icon={['fal', 'times']}
          onClick={cancelNewProjectContactRow}
        />
      ) : (
        originNode
      ),
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const saveNewProjectContact = async () => {
    setIsLoading(true);
    const {
      contactId,
      projectId,
      projectRoles,
      jobTitle,
      hourlyRateId,
      emailNotificationEnabled,
      isAddToAllSubprojects,
    } = newProjectContact;
    dispatch(
      createInternalProjectContact({
        contactId,
        projectId,
        projectRoles: projectRoles as ProjectRole[],
        jobTitle,
        hourlyRateId,
        emailNotificationEnabled,
        isArchived: false,
        isInternal: true,
        isAddToAllSubprojects,
      })
    );
    setIsLoading(false);
    cancelNewProjectContactRow();
    updateNewProjectContact('clear');
  };

  // const deleteProjectContact = (
  //   internalProjectContactId: InternalProjectContactId
  // ) => {
  //   const contactItem = (
  //     originalContactItems as InternalProjectContactItem[]
  //   ).find(
  //     (projectContact) =>
  //       projectContact.internalProjectContact.internalProjectContactId ===
  //       internalProjectContactId
  //   );
  //   dispatch(
  //     archiveInternalProjectContact(
  //       internalProjectContactId,
  //       contactItem.internalProjectContact.projectId,
  //       contactItem.internalProjectContact
  //     )
  //   );
  //   setModalDeleteContactVisible(false);
  //   setContactToDelete(null);
  // };

  const deleteProjectContact = (
    internalProjectContactId: InternalProjectContactId
  ) => {
    const contactItem = (
      originalContactItems as InternalProjectContactItem[]
    ).find(
      (projectContact) =>
        projectContact.internalProjectContact.internalProjectContactId ===
        internalProjectContactId
    );

    const updatedContact: InternalProjectContact = {
      projectId: projectId,
      contactId: contactItem.contact.contactId,
      internalProjectContactId: internalProjectContactId,
      hourlyRateId: contactItem.internalProjectContact.hourlyRateId,
      jobTitle: contactItem.internalProjectContact.jobTitle,
      projectRoles: contactItem.internalProjectContact.projectRoles,
      emailNotificationEnabled:
        contactItem.internalProjectContact.emailNotificationEnabled,
      isArchived: true,
    };

    const updatedContactList = contactItems
      .filter(
        (item) =>
          item.internalProjectContact.internalProjectContactId !==
          internalProjectContactId
      )
      .map((item) => item.internalProjectContact);

    dispatch(
      updateProjectContacts(projectId, [updatedContact], updatedContactList)
    );

    dispatch(fetchInternalProjectContacts(projectId));
    setModalDeleteContactVisible(false);
    setContactToDelete(null);
  };

  const cancelChanges = () => {
    setContactItems(originalContactItems);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    const changedContacts = contactItems
      .filter(
        (contactItem) =>
          !equals(
            contactItem,
            originalContactItems.find(
              (originalContactItem) =>
                originalContactItem.internalProjectContact
                  .internalProjectContactId ===
                contactItem.internalProjectContact.internalProjectContactId
            )
          )
      )
      .map((contactItem) => contactItem.internalProjectContact);

    const originalItems = originalContactItems
      .map(({ internalProjectContact }) => internalProjectContact)
      .filter(({ internalProjectContactId: originalId }) =>
        changedContacts.some(
          ({ internalProjectContactId: changedId }) => changedId === originalId
        )
      );

    const contactsWithChangedHourlyRate = changedContacts.filter(
      (contactItem) => {
        const originalContact = originalItems.find(
          (originalItem) =>
            originalItem.internalProjectContactId ===
            contactItem.internalProjectContactId
        );
        return (
          originalContact &&
          contactItem.hourlyRateId !== originalContact.hourlyRateId
        );
      }
    );

    if (changedContacts.length > 0) {
      const res = (await dispatch(
        updateProjectContacts(
          projectId,
          changedContacts.map(
            ({
              projectId,
              contactId,
              internalProjectContactId,
              hourlyRateId,
              jobTitle,
              projectRoles,
              emailNotificationEnabled,
            }) => ({
              projectId,
              contactId,
              internalProjectContactId,
              hourlyRateId,
              jobTitle,
              projectRoles,
              emailNotificationEnabled,
            })
          ),
          originalItems
        )
      )) as unknown as Response;
      if (res.ok) {
        setOriginalContactItems(contactItems);
      }
    }

    if (contactsWithChangedHourlyRate.length > 0) {
      const originalItemsForHourlyRateChange = originalItems.filter(
        ({ internalProjectContactId: originalId }) =>
          contactsWithChangedHourlyRate.some(
            ({ internalProjectContactId: changedId }) =>
              changedId === originalId
          )
      );

      const response = (await dispatch(
        updateInternalProjectContact(
          projectId,
          contactsWithChangedHourlyRate.map(
            ({
              projectId,
              contactId,
              internalProjectContactId,
              hourlyRateId,
              jobTitle,
              emailNotificationEnabled,
            }) =>
              ({
                projectId,
                contactId,
                internalProjectContactId,
                hourlyRateId,
                jobTitle,
                emailNotificationEnabled,
              }) as InternalProjectContact
          ),
          originalItemsForHourlyRateChange
        )
      )) as unknown as Response;
      if (response.ok) {
        setOriginalContactItems(contactItems);
      }
    }
    setIsLoading(false);
  };

  const enableNewProjectContactRow = () => setNewProjectContactRowActive(true);
  const cancelNewProjectContactRow = () => setNewProjectContactRowActive(false);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setContactItems(propsContactItems);
    setOriginalContactItems(propsContactItems);
  }, [propsContactItems]);

  useEffect(() => {
    if (addUserActive && tableBottomRef?.current) {
      tableBottomRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [addUserActive, tableBottomRef]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.rootsRoot, className)}>
      <Table
        className={classNames(classes.root, className)}
        columns={columns}
        pagination={false}
        scroll={{
          x: '100%',
          y: 'calc(100% - 56px)',
        }}
        dataSource={
          addUserActive
            ? [
                ...data,
                {
                  key: 'addUser',
                  name: '',
                  projectRoles: '',
                  jobTitle: '',
                  hourlyRateId: '',
                  emailNotificationEnabled: false,
                  addUserRow: true,
                },
              ]
            : data
        }
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        locale={tableTranslations(t)}
        loading={{
          spinning: loading,
          indicator: <PrioSpinner alignSelf />,
        }}
        rowClassName={(record) => {
          if (record.addUserRow) {
            return classes.addedRow;
          } else {
            return classes.row;
          }
        }}
        sticky
      />

      <Flex.Row className={classes.footer}>
        <Button
          onClick={enableNewProjectContactRow}
          disabled={isDirty || addUserActive || isSubproject}
          iconProp={['fal', 'user-plus']}
          loading={isLoading}
        >
          {t('common:add')}
        </Button>
        <Flex.Item flex={1} />
        <Flex.Row childrenGap={5}>
          <Flex.Item>
            <Button onClick={cancelChanges} disabled={!isDirty} type="link">
              {t('common:actions.cancel')}
            </Button>
          </Flex.Item>
          <Flex.Item>
            <Button
              onClick={saveChanges}
              disabled={!isDirty || addUserActive}
              loading={isLoading}
            >
              {t('common:save')}
            </Button>
          </Flex.Item>
        </Flex.Row>
      </Flex.Row>
      <Modal
        visible={modalDeleteContactVisible}
        onOk={() => deleteProjectContact(contactToDelete)}
        onCancel={() => {
          setModalDeleteContactVisible(false);
        }}
        title={t('projects:modalDeleteProjectContact.title')}
        okText={t('projects:modalDeleteProjectContact.actionButton')}
        cancelText={t('projects:modalDeleteProjectContact.cancelButton')}
      >
        {t('projects:modalDeleteProjectContact.internalContact.content')}
      </Modal>
    </Flex.Column>
  );
};

export default InternalProjectContactsTable;
