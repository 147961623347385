import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { ProjectJobTitleSuggestionDto } from '../../../models/ProjectJobTitle';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-row .ant-table-cell .project-job-title-table-delete-button': {
      visibility: 'hidden',
    },
    '& .ant-table-row:hover .ant-table-cell .project-job-title-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
}));

type ProjectJobTitleWithDirty = ProjectJobTitleSuggestionDto & {
  isDirty?: boolean;
};

interface ProjectJobTitlesTableProps {
  projectJobTitles: ProjectJobTitleWithDirty[];
  className?: string;
  setUpdatedProjectJobTitles?: (updated: ProjectJobTitleWithDirty[]) => void;
  deleteProjectJobTitle?: (name: string) => void;
}

export const ProjectJobTitlesTable: React.FC<ProjectJobTitlesTableProps> = ({
  projectJobTitles,
  setUpdatedProjectJobTitles,
  deleteProjectJobTitle,
  className,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] =
    useState<ProjectJobTitleWithDirty[]>(projectJobTitles);
  // Update specific field and mark as dirty
  const updateField = (index: number, key: string, value: any) => {
    // Update only the specified item and mark it as dirty
    const newData = data.map((item, i) => {
      if (i === index) {
        return { ...item, [key]: value, isDirty: true };
      }
      return item;
    });

    // Update the state
    setData(newData);

    // Pass the updated data to the parent function
    if (setUpdatedProjectJobTitles) {
      setUpdatedProjectJobTitles(newData); // Use the updated state
    }
  };

  // Remove Row
  const handleRemove = (name: string) => {
    if (deleteProjectJobTitle) {
      deleteProjectJobTitle(name);
    }
  };

  useEffect(() => {
    setData(projectJobTitles);
  }, [projectJobTitles]);

  const columns: ColumnProps<ProjectJobTitleWithDirty>[] = [
    {
      title: t('settings:jobTitles.table.name'),
      dataIndex: 'name',
      render: (text, _, index) => (
        <Input
          value={text}
          disabled={
            data[index].isFromInternalList || data[index].isFromExternalList
          }
          onChange={(e) => updateField(index, 'name', e.target.value)}
        />
      ),
    },
    /*
    {
      title: t('settings:jobTitles.table.internal'),
      dataIndex: 'useInternalProjectJobTitle',
      render: (value, _, index) => (
        <Switch
          checked={value}
          disabled={
            data[index].isFromInternalList || data[index].isFromExternalList
          }
          onChange={(checked) =>
            updateField(index, 'useInternalProjectJobTitle', checked)
          }
        />
      ),
    },
    {
      title: t('settings:jobTitles.table.external'),
      dataIndex: 'useExternalProjectJobTitle',
      render: (value, _, index) => (
        <Switch
          checked={value}
          disabled={
            data[index].isFromInternalList || data[index].isFromExternalList
          }
          onChange={(checked) =>
            updateField(index, 'useExternalProjectJobTitle', checked)
          }
        />
      ),
    },
    */
    {
      title: t('settings:jobTitles.table.delete'),
      render: (_, record, index) => (
        <Button
          // handle remove name
          onClick={() => handleRemove(record.name)}
          className="project-job-title-table-delete-button"
          disabled={
            data[index].isFromInternalList || data[index].isFromExternalList
          }
          iconProp={['fal', 'trash']}
          type="link"
        />
      ),
    },
  ];

  return (
    <Table
      className={classNames(classes.root, className)}
      dataSource={data.map((item, index) => ({ ...item, key: index }))}
      columns={columns}
      pagination={false}
    />
  );
};

export default ProjectJobTitlesTable;
