import { DriveItem } from '../../../models/Drive';
import { GroupId } from '../../../models/Types';
import { apiGenerateXInvoicePreview, apiGetBlobOfDriveItem } from '../api';

export const useDownloadXInvoice = (groupId: GroupId) => {
  const onDownloadXInvoice = async (driveItem: DriveItem) => {
    const blob = await apiGetBlobOfDriveItem(groupId, driveItem);

    const file = new File([blob], driveItem.name, { type: blob.type });

    const formData = new FormData();
    formData.set('title', driveItem.name);
    formData.set('xRechnung', file);

    const result = await apiGenerateXInvoicePreview(formData);

    const url = URL.createObjectURL(result);
    const name = `${driveItem.name.split('.').slice(0, -1).join('.')}.pdf`;

    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return { onDownloadXInvoice };
};
