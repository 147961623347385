import React, { useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import {
  AbsenceManagementTable,
  HRAbsenceManagementTableRefProps,
} from './AbsenceManagementTable';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import { OfficeId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import { getOfficesByIdState } from '../../../apps/main/rootReducer';
import AbsenceProposalDetailsDrawer from './AbsenceProposalDetailsDrawer';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import classNames from 'classnames';
import { DefaultSearchParameterItem } from '../../../components/Filter/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: 24,
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface HRAbsenceProposalPageProps {
  className?: string;
  officeId?: OfficeId;
  customDefaultSearchParameters?: DefaultSearchParameterItem[];
  ignoreBackendDefaultSearchParameters?: boolean;
}

export const HRAbsenceProposalPage: React.FC<HRAbsenceProposalPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    officeId,
    customDefaultSearchParameters = [],
    ignoreBackendDefaultSearchParameters = false,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef = useRef<HRAbsenceManagementTableRefProps>(null);

  const [absenceProposalDrawerOpen, setAbsenceProposalDrawerOpen] =
    useState<boolean>(false);

  const [clickedAbsenceProposal, setClickedAbsenceProposal] =
    useState<AbsenceProposal | null>(null);

  const officesById = useSelector(getOfficesByIdState);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleRowClick = (absenceProposal: AbsenceProposal) => {
    setClickedAbsenceProposal(absenceProposal);
    setAbsenceProposalDrawerOpen(true);
  };

  const handleOnFetchSearch = () => {
    if (tableRef.current) {
      tableRef.current.resetActiveFilters();
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <FilterContextProvider<AbsenceProposal>
        searchType="absenceProposals"
        equalityFunction={(a, b) =>
          a.data.absenceProposalId === b.data.absenceProposalId
        }
        customDefaultSearchParameters={customDefaultSearchParameters}
        transformedMap={{
          'Transformed.Timespan': ['Data.From', 'Data.To'],
        }}
        ignoreBackendDefaultSearchParameters={
          ignoreBackendDefaultSearchParameters
        }
      >
        <FilterBar onFetchSearch={handleOnFetchSearch} />
        <AbsenceManagementTable
          ref={tableRef}
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          officeId={officeId}
          onRowClick={handleRowClick}
        />
        <AbsenceProposalDetailsDrawer
          absenceProposalDrawerOpen={absenceProposalDrawerOpen}
          setAbsenceProposalDrawerOpen={setAbsenceProposalDrawerOpen}
          absenceProposal={clickedAbsenceProposal}
          officesById={officesById}
          setClickedAbsenceProposal={setClickedAbsenceProposal}
          officeId={officeId}
        />
      </FilterContextProvider>
    </Flex.Column>
  );
};

export default HRAbsenceProposalPage;
