import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { ProjectId } from '../../../models/Types';
import Table, { ColumnProps } from 'antd/lib/table';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectAssignment } from '../../../models/Project';
import classNames from 'classnames';
import ProjectAssignmentProjectPicker from './ProjectAssignment/ProjectAssignmentProjectPicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useQuery } from '@tanstack/react-query';
import { apiFetchExternalJobTitleSuggestions } from '../../settings/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-row .ant-table-cell .create-contact-form-project-assignment-deleteButton':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .create-contact-form-project-assignment-deleteButton':
      {
        visibility: 'visible',
      },
  },
  projectPicker: { width: '100%', maxWidth: '190px' },
  jobTitleAutoComplete: {
    width: '100%',
    '&.ant-select-single .ant-select-selector': {
      paddingLeft: 0,
    },
    '& .ant-select-selection-item': {
      color: theme.old.palette.primaryColor,
    },
  },
}));

interface TableRow {
  key: React.Key;
  projectId?: ProjectId | ProjectId[];
  jobTitle?: string;
  isAddable?: boolean;
  isDeleteable?: boolean;
}

interface ProjectAssignmentTableProps {
  className?: string;
  onTableRowChange: (rowCount: number) => void;
  onProjectAssignmentChange: (
    currentProjectAssignments: ProjectAssignment[]
  ) => void;
}

export interface ProjectAssignmentTableRefObject {
  showAlert(): void;
}

export const ProjectAssignmentTable: React.FC<ProjectAssignmentTableProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { className, onTableRowChange, onProjectAssignmentChange } = props;

  const creatorRow: TableRow = {
    key: 'creatorRow',
    isAddable: true,
    isDeleteable: true,
  };

  const { data: __externalJobTitles } = useQuery({
    queryKey: ['externalJobTitleSuggestions'],
    queryFn: apiFetchExternalJobTitleSuggestions,
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });

  const jobTitleSuggestions = __externalJobTitles?.data ?? [];
  //#endregion

  //#region -------------------------------- State declaration
  const [tableRows, setTableRows] = useState<TableRow[]>([creatorRow]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [currentProjectPick, setProjectCurrentPick] = useState<ProjectId>('');
  const [currentJobTitlePick, setCurrentJobTitlePick] = useState<string>('');

  const [projectAssignments, setProjectAssignments] = useState<
    ProjectAssignment[]
  >([]);

  const [isValidRow, setIsValidRow] = useState<boolean>(false);
  //#endregion

  //#region -------------------------------- Methods
  const updateTableRowState = <K extends keyof TableRow>(
    key: React.Key,
    propertyToUpdate: K,
    newValue: TableRow[K]
  ) => {
    const index = tableRows.findIndex((item) => item.key === key);

    const rowToUpdate = tableRows[index];
    rowToUpdate[propertyToUpdate] = newValue;
    setTableRows([
      ...tableRows.slice(0, index),
      rowToUpdate,
      ...tableRows.slice(index + 1),
    ]);
  };
  //#endregion

  //#region -------------------------------- Handle methods

  const handleProjectPick = (pickedProject: ProjectId, record: TableRow) => {
    pickedProject ? setIsValidRow(true) : setIsValidRow(false);
    setProjectCurrentPick(pickedProject);
    updateTableRowState(record.key, 'projectId', pickedProject);
  };

  const handleJobTitlePick = (pickedJobTitle: string, record: TableRow) => {
    setCurrentJobTitlePick(pickedJobTitle);
    updateTableRowState(record.key, 'jobTitle', pickedJobTitle);
  };

  const handleAdd = (record: TableRow) => {
    const newProjectAssignment: ProjectAssignment = {
      key: rowCount,
      projectId: currentProjectPick,
      jobtitle: currentJobTitlePick,
    };

    const newProjectAssignmentRow: TableRow = {
      key: rowCount,
      projectId: record.projectId,
      jobTitle: record.jobTitle,
      isAddable: false,
      isDeleteable: true,
    };

    if (tableRows.length >= 1) {
      updateTableRowState('creatorRow', 'isDeleteable', false);
    }

    updateTableRowState('creatorRow', 'projectId', '');
    updateTableRowState('creatorRow', 'jobTitle', '');

    setProjectAssignments([...projectAssignments, newProjectAssignment]);
    setTableRows([...tableRows, newProjectAssignmentRow]);
    setRowCount(rowCount + 1);
    onTableRowChange([...tableRows, tableRows].length);
    setIsValidRow(false);
  };

  const handleDelete = (key: React.Key) => {
    if (tableRows.length === 2) {
      const index = tableRows.findIndex(
        (tableRow) => tableRow.key === 'creatorRow'
      );
      const rowToUpdate = tableRows[index];
      rowToUpdate.isDeleteable = true;
      setTableRows([
        ...tableRows.slice(0, index),
        rowToUpdate,
        ...tableRows.slice(index + 1),
      ]);
    }
    setProjectAssignments([
      ...projectAssignments.filter(
        (projectAssignment) => projectAssignment.key !== key
      ),
    ]);
    setTableRows([
      ...tableRows.filter(
        (projectAssignmentTableRow) => projectAssignmentTableRow.key !== key
      ),
    ]);

    if (tableRows.length === 1) {
      onTableRowChange(0);
    }
  };
  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    if (onProjectAssignmentChange) {
      onProjectAssignmentChange(projectAssignments);
    }
  }, [projectAssignments, onProjectAssignmentChange]);

  //#endregion

  //#region -------------------------------- Components
  const columns: ColumnProps<TableRow>[] = [
    {
      title: t('projects:projectAssignment.columnTitles.projectName'),
      dataIndex: 'project',
      render: (_, record: TableRow) => (
        <ProjectAssignmentProjectPicker
          className={classes.projectPicker}
          value={record.projectId}
          onChange={(value) => {
            handleProjectPick(value, record);
          }}
        />
      ),
      width: theme.old.spacing.unit(40),
    },
    {
      title: t('projects:projectAssignment.columnTitles.jobTitle'),
      dataIndex: 'jobTitle',
      render: (_, record: TableRow) => (
        <AutoComplete
          className={classes.jobTitleAutoComplete}
          onChange={(value) => {
            handleJobTitlePick(value, record);
          }}
          value={record.jobTitle}
          allowClear
        >
          {jobTitleSuggestions.map((jobTitleSuggestion) => (
            <AutoComplete.Option
              key={jobTitleSuggestion.projectJobTitleSuggestionId}
              value={jobTitleSuggestion.name}
            >
              {jobTitleSuggestion.name}
            </AutoComplete.Option>
          ))}
        </AutoComplete>
      ),
      width: theme.old.spacing.unit(40),
    },
    {
      dataIndex: 'operation',
      render: (_, record: TableRow) => (
        <Flex.Row childrenGap={theme.old.spacing.unit(0.5)}>
          {record.isAddable && (
            <Flex.Item>
              <Button
                className={classNames(
                  'create-contact-form-project-assignment-creatorRow-addButton'
                )}
                onClick={() => handleAdd(record)}
                disabled={isValidRow ? false : true}
                iconProp={['fal', 'plus']}
              ></Button>
            </Flex.Item>
          )}
          {record.isDeleteable && (
            <Flex.Item>
              <Button
                className={
                  record.key === 'creatorRow'
                    ? classNames(
                        'create-contact-form-project-assignment-creatorRow-deleteButton'
                      )
                    : classNames(
                        'create-contact-form-project-assignment-deleteButton'
                      )
                }
                onClick={() => handleDelete(record.key)}
                iconProp={['fal', 'trash']}
                type="link"
              ></Button>
            </Flex.Item>
          )}
        </Flex.Row>
      ),
    },
  ];
  //#endregion

  return (
    <Table
      className={classNames(classes.root, className)}
      dataSource={tableRows}
      columns={columns}
      pagination={false}
    />
  );
};

export default ProjectAssignmentTable;
