import VirtualTable, {
  VirtualTableProps,
} from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import useFilterContext from './hooks/useFilterContext';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import { SortingProps } from '@prio365/prio365-react-library/lib/VirtualList/hooks/useSortBy';
import { VirtualTableBodyRef } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTableBody';
import { ActiveFiltersType } from '@prio365/prio365-react-library/lib/VirtualTable/components/InnerVirtualTable';

function FilterContextVirtualTable<
  Data = unknown,
  DragObject = unknown,
  DropResult = unknown,
>(
  props: VirtualTableProps<Data, DragObject, DropResult>,
  ref: ForwardedRef<VirtualTableBodyRef<Data>>
) {
  //#region ------------------------------ Defaults
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const innerRef = useRef<VirtualTableBodyRef<Data>>(null);

  const { tableRef } = useFilterContext();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Ref
  useImperativeHandle(ref, () => {
    return {
      forceUpdate: () => {
        innerRef.current?.forceUpdate();
      },
      recomputeRowHeights: () => {
        innerRef.current?.recomputeRowHeights();
      },
      scrollToRow: (index: number) => {
        innerRef.current?.scrollToRow(index);
      },
      setSelectedIndexes: (selectedIndexes: number[]) => {
        innerRef.current?.setSelectedIndexes(selectedIndexes);
      },
      getSelectedIndexes: () => {
        return innerRef.current?.getSelectedIndexes();
      },
      setActiveIndex: (index: number) => {
        innerRef.current?.setActiveIndex(index);
      },
      getActiveIndex: () => {
        return innerRef.current?.getActiveIndex();
      },
      setLastSelectedItemIndex: (index: number) => {
        innerRef.current?.setLastSelectedItemIndex(index);
      },
      setShiftSelectionIsActive: (isActive: boolean) => {
        innerRef.current?.setShiftSelectionIsActive(isActive);
      },
      scrollToPosition: (scrollTop: number) => {
        innerRef.current?.scrollToPosition(scrollTop);
      },
      setScrollPosition: ({ scrollTop }) => {
        innerRef.current.scrollToPosition(scrollTop);
      },
      setSelectAll: (selectAll: boolean) => {
        innerRef.current?.setSelectAll(selectAll);
      },
      setSortingProps: (sortingProps: SortingProps<Data>) => {
        innerRef.current?.setSortingProps(sortingProps);
      },
      getSortingProps: () => {
        return innerRef.current?.getSortingProps();
      },
      setActiveFilters: (activeFilters: ActiveFiltersType) => {
        innerRef.current?.setActiveFilters(activeFilters);
      },
    };
  });
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <VirtualTable<Data, DragObject, DropResult>
      {...props}
      ref={(element) => {
        if (tableRef) {
          tableRef.current = element;
        }
        innerRef.current = element;
      }}
    />
  );
}

export default forwardRef(FilterContextVirtualTable) as <
  Data,
  DragObject = unknown,
  DropResult = unknown,
>(
  props: VirtualTableProps<Data, DragObject, DropResult> & {
    ref?: ForwardedRef<VirtualTableBodyRef<Data>>;
  }
) => JSX.Element;
