import React, { useState, useEffect, useCallback } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import {
  apiDeleteProjectJobTitleSuggestion,
  apiFetchProjectJobTitleSuggestions,
  apiUpdateProjectJobTitleSuggestion,
} from '../api';
import ProjectJobTitlesTable from './ProjectJobTitlesTable';
import { notification } from 'antd';
import { ProjectJobTitleSuggestionDto } from '../../../models/ProjectJobTitle';
import ProjectJobTitlesDrawer from './ProjectJobTitlesDrawer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  saveBar: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#f5f5f5',
    borderTop: '1px solid #ddd',
  },
}));

type ProjectJobTitleWithDirty = ProjectJobTitleSuggestionDto & {
  isDirty?: boolean;
};

interface ProjectJobTitlesPageProps {
  className?: string;
}

const ProjectJobTitlesPage: React.FC<ProjectJobTitlesPageProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [projectJobTitles, setProjectJobTitles] = useState<
    ProjectJobTitleWithDirty[]
  >([]);
  const [originalJobTitles, setOriginalJobTitles] = useState<
    ProjectJobTitleSuggestionDto[]
  >([]);

  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const fetchJobTitles = useCallback(async () => {
    try {
      const { data } = await apiFetchProjectJobTitleSuggestions();
      data.sort((a, b) => a.name.localeCompare(b.name));
      setProjectJobTitles(data.map((title) => ({ ...title, isDirty: false })));
      setOriginalJobTitles(data); // Speichert die Originaldaten
    } catch (error) {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.fetchError'),
      });
    }
  }, [t]);

  useEffect(() => {
    fetchJobTitles();
  }, [fetchJobTitles]);

  const handleJobTitleChange = (
    updatedJobTitles: ProjectJobTitleSuggestionDto[]
  ) => {
    const hasChanges = updatedJobTitles.some((title, index) => {
      const original = originalJobTitles[index];
      return JSON.stringify(title) !== JSON.stringify(original);
    });

    setProjectJobTitles(updatedJobTitles);
    setIsSaveEnabled(hasChanges);
  };

  const handleDeleteJobTitle = async (name: string) => {
    try {
      const { result } = await apiDeleteProjectJobTitleSuggestion(name);
      if (result.status < 200 || result.status >= 300) {
        throw new Error();
      }
      fetchJobTitles(); // Refresh data
    } catch {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.deleteTitleSuggestionError'),
      });
    }
  };

  // Save Changed Entries
  const handleSave = async () => {
    setIsSaving(true);
    const dirtyEntries = projectJobTitles.filter((title) => title.isDirty);

    try {
      await Promise.all(
        dirtyEntries.map(async (title) => {
          if (title.isFromInternalList || title.isFromExternalList) {
            return;
          }
          const { result } = await apiUpdateProjectJobTitleSuggestion(title);
          if (result.status < 200 || result.status >= 300) {
            throw new Error();
          }
        })
      );

      fetchJobTitles();
      setIsSaveEnabled(false);
    } catch {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.updateTitleSuggestionError'),
      });
      fetchJobTitles();
      setIsSaveEnabled(false);
    }
    setIsSaving(false);
  };

  return (
    <div className={classes.root}>
      {/* Header Bar */}
      <div className={classes.headerBar}>
        <Button type="primary" onClick={() => setIsDrawerVisible(true)}>
          {t('common:add')}
        </Button>
      </div>

      {/* Table Container */}
      <div className={classes.tableContainer}>
        <ProjectJobTitlesTable
          projectJobTitles={projectJobTitles}
          deleteProjectJobTitle={handleDeleteJobTitle}
          setUpdatedProjectJobTitles={(updatedJobTitles) => {
            // only dirty flags update
            handleJobTitleChange(updatedJobTitles);
          }}
        />
      </div>

      {/* Save Bar */}
      <div className={classes.saveBar}>
        <Button
          type="primary"
          disabled={!isSaveEnabled || isSaving}
          onClick={handleSave}
        >
          {t('common:save')}
        </Button>
      </div>

      {/* Drawer */}
      <ProjectJobTitlesDrawer
        visible={isDrawerVisible}
        closeDrawer={() => {
          setIsDrawerVisible(false);
          fetchJobTitles();
        }}
      />
    </div>
  );
};

export default ProjectJobTitlesPage;
