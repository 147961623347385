import React, { useState } from 'react';
import {
  Drawer,
  Input,
  Button,
  useTheme,
} from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { ContactClassificationDto } from '../../../models/ContactClassifications';
import { apiCreateContactClassification } from '../api';
import { PrioTheme } from '../../../theme/types';

interface ContactClassificationDrawerProps {
  visible: boolean;
  closeDrawer: VoidFunction;
  onSaveSuccess?: () => void;
}

const ContactClassificationDrawer: React.FC<
  ContactClassificationDrawerProps
> = ({ visible, closeDrawer, onSaveSuccess }) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = async (values: ContactClassificationDto) => {
    setIsSaving(true);
    try {
      const { result } = await apiCreateContactClassification(values);
      if (result.status >= 200 && result.status < 300) {
        form.resetFields();
        if (onSaveSuccess) onSaveSuccess();
        closeDrawer();
        setIsSaveEnabled(false); // Reset save button
      } else {
        throw new Error();
      }
    } catch (error) {
      notification.error({
        message: t('common:error'),
        description: t('settings:contactClassification.drawer.errorMessage'),
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    closeDrawer();
    setIsSaveEnabled(false);
  };

  const handleValuesChange = (
    _,
    allValues: Partial<ContactClassificationDto>
  ) => {
    setIsSaveEnabled(!!allValues.name && !!allValues.description);
  };
  //#endregion

  return (
    <Drawer
      title={t('settings:contactClassification.drawer.title')}
      visible={visible}
      onClose={handleCancel}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleCancel}
            style={{ marginRight: theme.spacing.small }}
            type="default"
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isSaving}
            disabled={!isSaveEnabled} // Disabled if Save is not enabled
          >
            {t('common:save')}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onValuesChange={handleValuesChange} // Track form changes
      >
        <Form.Item
          name="name"
          label={t('settings:contactClassification.drawer.name')}
          rules={[{ required: true, message: t('common:required') }]}
        >
          <Input
            placeholder={t(
              'settings:contactClassification.drawer.namePlaceholder'
            )}
            full
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={t('settings:contactClassification.drawer.description')}
          rules={[{ required: true, message: t('common:required') }]}
        >
          <Input
            placeholder={t(
              'settings:contactClassification.drawer.descriptionPlaceholder'
            )}
            full
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ContactClassificationDrawer;
