import { useQuery } from '@tanstack/react-query';
import { apiFetchProjectMemberships } from '../api';
import useProjectsContext from '../../projects/hooks/useProjectsContext';

export const useProjectMembership = (contactId: string) => {
  const { getProjectById } = useProjectsContext();

  const { data: projectMemberships, isLoading: isFetchingProjectMember } =
    useQuery({
      queryKey: ['projectMemberships', contactId],
      queryFn: () => apiFetchProjectMemberships(contactId),
    });

  const projectIds =
    projectMemberships?.data?.items.map((item) => item.data.projectId) ?? [];
  const projects = projectIds
    .map((projectId) => getProjectById(projectId))
    .filter((project) => project)
    .filter(({ isArchived }) => !isArchived)
    .sort((a, b) => a.number.localeCompare(b.number));

  return {
    projects,
    isFetchingProjectMember,
  };
};
