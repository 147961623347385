import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { ContactClassificationDto } from '../../../models/ContactClassifications';
import { Button } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-row .ant-table-cell .contact-classification-delete-button': {
      visibility: 'hidden',
    },
    '& .ant-table-row:hover .ant-table-cell .contact-classification-delete-button':
      {
        visibility: 'visible',
      },
  },
}));

type ContactClassificationWithDirty = ContactClassificationDto & {
  isDirty?: boolean;
};

interface ContactClassificationTableProps {
  contactClassifications: ContactClassificationWithDirty[];
  setUpdatedClassifications: (
    updated: ContactClassificationWithDirty[]
  ) => void;
  deleteContactClassification: (id: string) => void;
  className?: string;
}

const ContactClassificationTable: React.FC<ContactClassificationTableProps> = ({
  contactClassifications,
  setUpdatedClassifications,
  deleteContactClassification,
  className,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [data, setData] = useState<ContactClassificationWithDirty[]>([]);

  useEffect(() => {
    setData(contactClassifications);
  }, [contactClassifications]);

  // Update a specific field and mark as dirty
  const updateField = (index: number, key: string, value: any) => {
    const updated = data.map((item, i) =>
      i === index ? { ...item, [key]: value, isDirty: true } : item
    );

    setData(updated);
    setUpdatedClassifications(updated);
  };

  // Delete a row
  const handleDelete = (name: string) => {
    deleteContactClassification(name);
  };

  const columns: ColumnProps<ContactClassificationWithDirty>[] = [
    {
      title: t('settings:contactClassification.table.name'),
      dataIndex: 'name',
      render: (text, _, index) => (
        <Input
          value={text}
          onChange={(e) => updateField(index, 'name', e.target.value)}
        />
      ),
    },
    {
      title: t('settings:contactClassification.table.description'),
      dataIndex: 'description',
      render: (text, _, index) => (
        <Input
          value={text}
          onChange={(e) => updateField(index, 'description', e.target.value)}
        />
      ),
    },
    {
      title: '',
      render: (_, record) => (
        <Button
          type="link"
          className="contact-classification-delete-button"
          onClick={() => handleDelete(record.name)}
          iconProp={['fal', 'trash']}
        ></Button>
      ),
    },
  ];

  return (
    <Table
      className={classNames(classes.root, className)}
      dataSource={data.map((item, index) => ({ ...item, key: index }))}
      columns={columns}
      pagination={false}
    />
  );
};

export default ContactClassificationTable;
