import React, { useState } from 'react';
import {
  Drawer,
  Input,
  Button,
  useTheme,
} from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { apiCreateCompanyClassification } from '../api';
import { CompanyClassificationDto } from '../../../models/CompanyClassifications';
import { PrioTheme } from '../../../theme/types';

interface CompanyClassificationDrawerProps {
  visible: boolean;
  closeDrawer: VoidFunction;
  onSaveSuccess?: () => void;
}

const CompanyClassificationDrawer: React.FC<
  CompanyClassificationDrawerProps
> = ({ visible, closeDrawer, onSaveSuccess }) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = async (values: CompanyClassificationDto) => {
    setIsSaving(true);
    try {
      const { result } = await apiCreateCompanyClassification(values);
      if (result.status >= 200 && result.status < 300) {
        form.resetFields();
        if (onSaveSuccess) onSaveSuccess();
        closeDrawer();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification.error({
        message: t('common:error'),
        description: t('settings:companyClassification.drawer.errorMessage'),
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    closeDrawer();
    setIsSaveEnabled(false);
  };

  const handleValuesChange = (
    _,
    allValues: Partial<CompanyClassificationDto>
  ) => {
    setIsSaveEnabled(!!allValues.name && !!allValues.description);
  };
  //#endregion

  return (
    <Drawer
      title={t('settings:companyClassification.drawer.title')}
      visible={visible}
      onClose={handleCancel}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleCancel}
            style={{ marginRight: theme.spacing.small }}
            type="default"
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isSaving}
            disabled={!isSaveEnabled}
          >
            {t('common:save')}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="name"
          label={t('settings:companyClassification.drawer.name')}
          rules={[{ required: true, message: t('common:required') }]}
        >
          <Input
            placeholder={t(
              'settings:companyClassification.drawer.namePlaceholder'
            )}
            full
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={t('settings:companyClassification.drawer.description')}
          rules={[{ required: true, message: t('common:required') }]}
        >
          <Input
            placeholder={t(
              'settings:companyClassification.drawer.descriptionPlaceholder'
            )}
            full
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CompanyClassificationDrawer;
