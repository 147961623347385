import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  MonthlyClose,
  MonthlyCloseCalculatedData,
  MonthlyCloseFilter,
  MonthlyCloseSearchResultItem,
} from '../../../../models/TimeKeeping';
import HRMonthlyClosePageTable, {
  HRMonthlyClosePageTableRef,
} from './HRMonthlyClosePageTable';
import HRMonthlyCloseDrawer from './HRMonthlyCloseDrawer';
import CreateCompensationPaymentsDrawer from './CreateCompensationPaymentsDrawer';
import { sortContactsHelper } from '../../../contacts/utils';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../../components/Filter/FilterBar';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import { OfficeId } from '../../../../models/Types';
import { apiFetchMonthlyClose } from '../../../timeKeeping/api';
import useAccessRights2 from '../../../users/hooks/useAccessRights2';
import moment from 'moment';
import { DefaultSearchParameterItem } from '../../../../components/Filter/types';

interface HRMonthlyClosePageProps {
  className?: string;
  officeId: OfficeId;
}

export interface HRMonthlyClosePageRefProps {
  openCreateCompensationPaymentDrawer: VoidFunction;
}

export const HRMonthlyClosePage = forwardRef<
  HRMonthlyClosePageRefProps,
  HRMonthlyClosePageProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const { className, officeId } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef = useRef<HRMonthlyClosePageTableRef>(null);

  const { checkGlobalRoles } = useAccessRights2();

  const { getContactById } = useContactsContext();

  const [selectedSearchItem, setSelectedSearchItem] =
    useState<MonthlyCloseSearchResultItem | null>(null);

  const [selectedSearchItems, setSelectedSearchItems] = useState<
    MonthlyCloseSearchResultItem[]
  >([]);

  const [
    showCreateCompensationPaymentsDrawer,
    setShowCreateCompensationPaymentsDrawer,
  ] = useState<boolean>(false);

  const customDefaultSearchParameters: DefaultSearchParameterItem[] = [
    {
      parameterName: 'Calculated.OfficeId',
      defaultValue: officeId,
      defaultMethod: 'eq',
    },
  ];
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnFetchSearch = () => {
    if (tableRef.current) {
      tableRef.current.resetActiveFilters();
    }
  };

  const onSearchItemCloseSelect = (item: MonthlyCloseSearchResultItem) => {
    setSelectedSearchItem(item);
  };

  const handleOnFinishDetailsTab = () => {
    setSelectedSearchItem(null);
  };

  const handleOnRowSelectionChange = (item: MonthlyCloseSearchResultItem[]) => {
    setSelectedSearchItems(item);
  };

  const handleOnCreateCompensationPaymentsDrawerClose = () => {
    if (tableRef.current) {
      const filter: MonthlyCloseFilter = {
        from: moment(selectedSearchItems[0]?.data?.month)
          .startOf('month')
          .toISOString(true)
          .split('T')[0],
        to: moment(selectedSearchItems[0]?.data?.month)
          .endOf('month')
          .toISOString(true)
          .split('T')[0],
      };
      tableRef.current.optimisticWrite(
        selectedSearchItems.map((item) => ({
          data: item.data,
          calculated: item.calculated,
          method: 'update',
          callback: async () => {
            const { result, data } = await apiFetchMonthlyClose(
              { ...filter, userIds: [item.data.employeeId] },
              checkGlobalRoles(['globalHR']) ? undefined : officeId
            );
            if (result.ok) {
              return {
                result,
                data: {
                  data: data[0],
                  calculated: item.calculated,
                },
              };
            }
            return { result, data: null };
          },
        }))
      );
    }
    setShowCreateCompensationPaymentsDrawer(false);
    setSelectedSearchItems([]);
  };
  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    openCreateCompensationPaymentDrawer: () => {
      setShowCreateCompensationPaymentsDrawer(true);
    },
  }));
  //#endregion

  return (
    <div className={className}>
      <FilterContextProvider<MonthlyClose, MonthlyCloseCalculatedData>
        searchType="monthlyCloses"
        equalityFunction={({ data: a }, { data: b }) =>
          a.monthlyCloseId === b.monthlyCloseId
        }
        customDefaultSearchParameters={customDefaultSearchParameters}
      >
        <FilterBar onFetchSearch={handleOnFetchSearch} />
        <HRMonthlyClosePageTable
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          ref={tableRef}
          selectedSearchItems={selectedSearchItems}
          tableId={'mcp1'}
          onRowClick={onSearchItemCloseSelect}
          onRowSelectionChange={handleOnRowSelectionChange}
        />
        <HRMonthlyCloseDrawer
          searchItem={selectedSearchItem}
          setSelectedSearchItem={setSelectedSearchItem}
          onFinishDetailsTab={handleOnFinishDetailsTab}
        />
        <CreateCompensationPaymentsDrawer
          visible={showCreateCompensationPaymentsDrawer}
          officeId={officeId}
          monthlyCloses={selectedSearchItems
            .map(({ data }) => data)
            .sort(
              ({ employeeId: employeeIdA }, { employeeId: employeeIdB }) => {
                const a = getContactById(employeeIdA);
                const b = getContactById(employeeIdB);
                return sortContactsHelper(a, b);
              }
            )}
          onClose={handleOnCreateCompensationPaymentsDrawerClose}
          isMonthlyClosePage
        />
      </FilterContextProvider>
    </div>
  );
});

export default HRMonthlyClosePage;
