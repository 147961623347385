import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import VirtualTable2, {
  LoadingProps,
  VColumn,
} from '../../../../components/VirtualTable/VirtualTable2';
import {
  DriveItem,
  DriveItemDropTarget,
  FolderDriveItem,
} from '../../../../models/Drive';
import { makePrioStyles } from '../../../../theme/utils';
import {
  checkIfOneNote,
  getPDFType,
  iconForFile,
  isDriveItemFolder,
} from '../../util';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentFolderChildren,
  getCurrentFolderItem,
  getDocumentSettings,
  getDriveItemIsFetching,
  getErrorOfDriveItem,
  getProjectByIdState,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { Project } from '../../../../models/Project';
import { DriveItemId, GroupId, ProjectId } from '../../../../models/Types';
import PrioSpinner from '../../../../components/PrioSpinner';
import {
  DndDriveItemDropResult,
  DndDriveItemDto,
  DND_TYPE_DRIVE_ITEM_FILE,
  DND_TYPE_EMAIL,
  DND_TYPE_EMAIL_ATTACHMENT,
} from '../../../../dnd/types';
import { OnMailAttachmentsDrop } from '../DraggableDriveItem';
import { DropTargetMonitor } from 'react-dnd';
import { copyItemsIntoFolder, fetchDriveItemsSagaAction } from '../../actions';
import { ScrollParams } from 'react-virtualized';
import { Empty, notification } from 'antd';
import { checkScrollSpeed } from '../../../../util/checkScrollSpeed';
import { apiFetchDocumentSearchResults, apiUnzipArchive } from '../../api';
import { DriveUserRemoteItem } from '../../../../models/Document';
import DocumentsTableContextMenu from '../DocumentsTableContextMenu';
import classNames from 'classnames';
import { Button, useKeyboardModifiers } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { useDriveItemActions } from '../../hooks/useDriveItemActions';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import DocumentsTableRowIcon, {
  useDocumentsTableRowIconStyles,
} from '../DocumentsTableRowIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiFetchSavedAttachmentMetadata } from '../../../mail/api';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  menuButton: {
    visibility: 'hidden',
    height: '100%',
    width: '40px',
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowMenuButtonIcon: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
  },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  maskedIcon: {
    '& mask g g': {
      transform: 'translate(11em, 9em) scale(.75)',
    },
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  rowFile: {
    cursor: 'default',
    '&:hover $menuButton': {
      visibility: 'visible',
    },
  },
  columnName: {
    padding: 0,
    paddingLeft: 10,
  },
  columnFile: {
    width: 40,
  },
  rowFolder: {
    cursor: 'pointer',
    '&:hover $menuButton': {
      visibility: 'visible',
    },
  },
  vTable: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
  },
}));

interface DocumentsWidgetTableProps {
  groupId: GroupId;
  activeProjectId: ProjectId;
  currentFolderDriveItemId: string;

  onDriveItemClick?: (driveItem: DriveItem, mimeType: string) => void;
  onRowOver?: (isOver: boolean) => void;

  onMenuRename?: (driveItem: DriveItem) => void;
  onMenuDelete?: (driveItem: DriveItem) => void;
  onMenuDownload?: (driveItem: DriveItem) => void;
  onMenuCopy?: (driveItem: DriveItem) => void;
  onMenuMove?: (driveItem: DriveItem) => void;
  onCopyAsPdf?: (driveItem: DriveItem) => void;
  onDndMove?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>
  ) => void;
  onDndCopy?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>
  ) => void;
  onMoveItemsIntoProject?: (
    driveItems: DriveItem[],
    destinationProject: Project,
    dropEffect: 'copy' | 'move'
  ) => void;
  onMailAttachmentsDrop?: OnMailAttachmentsDrop;
  searchInput?: string;
  setSearchInput?: (value: string) => void;
  setIsKeywordSearch?: (value: boolean) => void;
  isKeywordSearch?: boolean;
  searchDriveItemId?: DriveItemId;
  setSearchDriveItemId?: (value: DriveItemId) => void;
  isSearchingDocuments?: boolean;
  setIsSearchingDocuments?: (value: boolean) => void;
  setProjectIdFromUrl?: (value: ProjectId) => void;
  onOpenParentFolder?: (driveItem: DriveItem) => void;
  onEditFolderAccessRights: (driveItem: DriveItem) => void;
  onDownloadXInvoice: (driveItem: DriveItem) => void;
}

export const DocumentsWidgetTable: React.FC<DocumentsWidgetTableProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const documentsTableRowIconClasses = useDocumentsTableRowIconStyles();
  const { t } = useTranslation();
  const {
    groupId,
    activeProjectId,
    currentFolderDriveItemId,
    onDriveItemClick: handleDriveItemClick,
    onMenuRename,
    onMenuDelete,
    onCopyAsPdf,
    onMenuDownload,
    onMenuCopy,
    onMenuMove,
    onDndCopy,
    onDndMove,
    onMailAttachmentsDrop,
    onMoveItemsIntoProject,
    onRowOver,
    searchInput,
    setSearchInput,
    setIsKeywordSearch,
    searchDriveItemId,
    setSearchDriveItemId,
    isSearchingDocuments,
    setIsSearchingDocuments,
    setProjectIdFromUrl,
    isKeywordSearch,
    onOpenParentFolder,
    onEditFolderAccessRights,
    onDownloadXInvoice,
  } = props;

  const tableId = 'widgetBar';
  const dispatch = useDispatch();
  const { altIsPressed } = useKeyboardModifiers();
  const { openInBrowser, openLocally } = useDriveItemActions(
    groupId,
    activeProjectId
  );
  //#endregion

  //#region -------------------------------- State declaration
  const [selectedDriveItems, setSelectedDriveItems] = useState<DriveItem[]>([]);
  const [driveItemsFromSearch, setDriveItemsFromSearch] = useState<DriveItem[]>(
    []
  );
  const [noResult, setNoResult] = useState<boolean>(false);
  const isRoot = !currentFolderDriveItemId;
  const documentsSettings = useSelector(getDocumentSettings);
  const rootDriveItemFolderId = `root-group-${groupId}`;

  const projectsById = useSelector(getProjectByIdState);

  const theme = useTheme<PrioTheme>();

  //#endregion

  //#region -------------------------------- Selectors
  const driveItems = useSelector<RootReducerState, DriveItem[]>((state) =>
    getCurrentFolderChildren(
      state,
      searchDriveItemId ?? currentFolderDriveItemId ?? `root-group-${groupId}`
    )
  );

  const rootFolderDriveItem = useSelector<RootReducerState, DriveItem>(
    (state) =>
      getCurrentFolderItem(
        state,
        currentFolderDriveItemId ?? `root-group-${groupId}`
      )
  );

  const { isFetching, isFetchingWithNextLink } = useSelector<
    RootReducerState,
    { isFetching: boolean; isFetchingWithNextLink: boolean }
  >((state) =>
    getDriveItemIsFetching(
      state,
      isRoot ? `root-group-${groupId}` : currentFolderDriveItemId
    )
  );

  const dataToForceRender = useMemo(
    () => ({
      groupId,
      isRoot,
      driveItemIds: (driveItemsFromSearch
        ? driveItemsFromSearch
        : driveItems ?? []
      ).map(({ id }) => id),
      documentListSpacing: documentsSettings.documentListSpacing,
    }),
    [
      groupId,
      isRoot,
      driveItemsFromSearch,
      driveItems,
      documentsSettings.documentListSpacing,
    ]
  );

  const errorMessage = useSelector<RootReducerState, string>((state) =>
    getErrorOfDriveItem(
      state,
      searchDriveItemId ?? currentFolderDriveItemId ?? `root-group-${groupId}`
    )
  );
  //#endregion

  //#region -------------------------------- Methods
  const getLoading = (): LoadingProps => {
    if (
      isFetching &&
      !isFetchingWithNextLink &&
      driveItems &&
      driveItems.length === 0
    )
      return {
        loadingType: 'table',
        indicator: <PrioSpinner alignSelf />,
      };

    if (isFetchingWithNextLink)
      return {
        loadingType: 'footer',
        indicator: <PrioSpinner alignSelf />,
      };

    return null;
  };

  //#region -------------------------------- Handle methods
  const rowClass = (row: DriveItem): string => {
    const isOneNoteFile = checkIfOneNote(row);
    return isDriveItemFolder(row) && !isOneNoteFile
      ? classes.rowFolder
      : classes.rowFile;
  };
  const onDrop = (
    item: any,
    monitor: DropTargetMonitor<unknown, unknown>,
    target: FolderDriveItem
  ) => {
    if (monitor.didDrop()) {
      return undefined;
    }
    const itemType = monitor.getItemType();

    switch (itemType) {
      case DND_TYPE_DRIVE_ITEM_FILE: {
        return {
          type: 'driveItem',
          object: target,
        } as DriveItemDropTarget;
      }
      case DND_TYPE_EMAIL_ATTACHMENT: {
        if (onMailAttachmentsDrop) {
          onMailAttachmentsDrop(
            item.draggableMessageAttachment,
            target.driveItem,
            groupId,
            item.projectId,
            item.selectedAttachments
          );
        }
        return undefined;
      }
      case DND_TYPE_EMAIL: {
        return {
          droppedInWidgetDriveFolder: true,
          driveItemId: target.driveItem?.id,
          groupId,
        };
      }
    }
    return undefined;
  };

  const onDragEnd = (item, monitor) => {
    if (monitor.didDrop()) {
      const dndDriveItemDto = monitor.getItem() as DndDriveItemDto;
      const dropResult = monitor.getDropResult() as DndDriveItemDropResult;
      if (dropResult.type) {
        if (dropResult.type === 'project') {
          const destinationProjectDropResult = dropResult.object as Project;
          onMoveItemsIntoProject(
            dndDriveItemDto.driveItems,
            destinationProjectDropResult,
            dropResult.dropEffect
          );
          setSelectedDriveItems(
            selectedDriveItems.filter(
              (item) =>
                !dndDriveItemDto.driveItems.find(
                  (itemForCopyOrMove) => itemForCopyOrMove.id === item.id
                )
            )
          );
        }

        if (dropResult.type === 'driveItem') {
          const driveItemFolderDropResult =
            dropResult.object as FolderDriveItem;
          const isDestinationRoot =
            !driveItemFolderDropResult.driveItem.parentReference.path;

          const isDraggedItemSelected = dndDriveItemDto.driveItems.some(
            (x) => x.id === dndDriveItemDto.driveItem.id
          );
          const itemsForCopyOrMove = () => {
            if (!isDraggedItemSelected)
              return [
                {
                  name: dndDriveItemDto.driveItem.name,
                  driveItemId: dndDriveItemDto.driveItem.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                },
              ];
            return dndDriveItemDto.driveItems
              ? dndDriveItemDto.driveItems.map((item) => ({
                  name: item.name,
                  driveItemId: item.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                }))
              : [];
          };

          const originalItemsForCopyOrMove = () => {
            if (!isDraggedItemSelected) return [dndDriveItemDto.driveItem];
            return dndDriveItemDto.driveItems ? dndDriveItemDto.driveItems : [];
          };

          if (
            !(
              itemsForCopyOrMove()?.[0]?.deleteSourceDriveItem &&
              (dndDriveItemDto.sourceFolderId ===
                driveItemFolderDropResult.driveItem.id ||
                (isDestinationRoot &&
                  dndDriveItemDto.root.sourceIsRoot &&
                  groupId === driveItemFolderDropResult.groupId))
            )
          ) {
            dispatch(
              copyItemsIntoFolder(
                dndDriveItemDto.projectId,
                groupId,
                driveItemFolderDropResult.groupId,
                itemsForCopyOrMove(),
                originalItemsForCopyOrMove(),
                dndDriveItemDto.sourceFolderId,
                driveItemFolderDropResult.driveItem.id,
                dndDriveItemDto.root.sourceIsRoot,
                isDestinationRoot
              )
            );
          }

          setSelectedDriveItems(
            selectedDriveItems.filter(
              (item) =>
                !itemsForCopyOrMove().find(
                  (itemForCopyOrMove) =>
                    itemForCopyOrMove.driveItemId === item.id
                )
            )
          );
          // onDndMove(item, monitor);
          // handleChangeDriveItems && handleChangeDriveItems([]);
        }

        if (dropResult.type === 'remoteDriveItem') {
          const remoteDriveItemDropResult =
            dropResult.object as DriveUserRemoteItem;
          const isDestinationRoot = remoteDriveItemDropResult.isRootFolder;

          const isDraggedItemSelected = dndDriveItemDto.driveItems.some(
            (x) => x.id === dndDriveItemDto.driveItem.id
          );
          const itemsForCopyOrMove = () => {
            if (!isDraggedItemSelected)
              return [
                {
                  name: dndDriveItemDto.driveItem.name,
                  driveItemId: dndDriveItemDto.driveItem.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                },
              ];
            return dndDriveItemDto.driveItems
              ? dndDriveItemDto.driveItems.map((item) => ({
                  name: item.name,
                  driveItemId: item.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                }))
              : [];
          };

          const originalItemsForCopyOrMove = () => {
            if (!isDraggedItemSelected) return [dndDriveItemDto.driveItem];
            return dndDriveItemDto.driveItems ? dndDriveItemDto.driveItems : [];
          };

          if (
            !(
              itemsForCopyOrMove()?.[0]?.deleteSourceDriveItem &&
              (dndDriveItemDto.sourceFolderId ===
                remoteDriveItemDropResult.remoteItemId ||
                (isDestinationRoot &&
                  dndDriveItemDto.root.sourceIsRoot &&
                  groupId === remoteDriveItemDropResult.groupId))
            )
          ) {
            dispatch(
              copyItemsIntoFolder(
                activeProjectId,
                groupId,
                remoteDriveItemDropResult.groupId,
                itemsForCopyOrMove(),
                originalItemsForCopyOrMove(),
                dndDriveItemDto.sourceFolderId,
                remoteDriveItemDropResult.remoteItemId,
                dndDriveItemDto.root.sourceIsRoot,
                isDestinationRoot
              )
            );
          }

          setSelectedDriveItems(
            selectedDriveItems.filter(
              (item) =>
                !itemsForCopyOrMove().find(
                  (itemForCopyOrMove) =>
                    itemForCopyOrMove.driveItemId === item.id
                )
            )
          );

          // onDndMove(item, monitor);
          // handleChangeDriveItems && handleChangeDriveItems([]);
        }
        dropResult.dropEffect === 'move' && onDndMove(item, monitor);
        dropResult.dropEffect === 'copy' && onDndCopy(item, monitor);
      }
    }
  };

  const onOver = (isOver: boolean) => {
    onRowOver(isOver);
  };

  const onChange = (items: DriveItem[]) => {
    setSelectedDriveItems(items);
    //handleChangeDriveItems && handleChangeDriveItems(items);
  };

  const onDriveItemClick = (driveItem: DriveItem, mimeType: string) => {
    const isOneNoteFile = checkIfOneNote(driveItem);
    const isFolder = isDriveItemFolder(driveItem) && !isOneNoteFile;
    if (isFolder) {
      setSelectedDriveItems([]);
    }
    if (isFolder || (isKeywordSearch && isFolder)) {
      handleDriveItemClick(driveItem, mimeType);
      setIsKeywordSearch(false);
      setIsSearchingDocuments(false);
      setSearchInput(null);
      setSearchDriveItemId(null);
    }
  };

  const onDriveItemDoubleClick = (
    driveItem: DriveItem,
    e: React.MouseEvent
  ) => {
    const isPdf = getPDFType(driveItem) === 'pdf';
    if (
      documentsSettings &&
      documentsSettings.doubleClickOpenBehaviour === 'local'
    ) {
      openLocally(driveItem, isPdf);
    }
    if (
      documentsSettings &&
      documentsSettings.doubleClickOpenBehaviour === 'browser'
    ) {
      openInBrowser(driveItem);
    }
  };

  const onScroll = useCallback(
    (params: ScrollParams) => {
      const scrollSpeed = checkScrollSpeed(params.scrollTop);
      var preLoadOffset;

      if (scrollSpeed > 1200) preLoadOffset = params.scrollHeight;
      if (scrollSpeed > 800) preLoadOffset = params.scrollHeight / 2;
      if (scrollSpeed > 400) preLoadOffset = params.scrollHeight / 3;
      if (scrollSpeed < 400) preLoadOffset = params.scrollHeight / 4;

      if (
        params.scrollHeight - params.scrollTop <=
        params.clientHeight + preLoadOffset
      ) {
        dispatch(
          fetchDriveItemsSagaAction(
            activeProjectId,
            groupId,
            currentFolderDriveItemId,
            250,
            isRoot,
            true,
            true
          )
        );
      }
    },
    [dispatch, groupId, isRoot, currentFolderDriveItemId, activeProjectId]
  );

  const triggerFunctions = (
    record: DriveItem,
    index
  ): HTMLAttributes<HTMLElement> => {
    return {
      onClick: (event) => {
        event.preventDefault();
        if (onDriveItemClick) {
          if (record.id) {
            onDriveItemClick(record, record?.file?.mimeType);
          }
        }
      },

      onDoubleClick: onDriveItemDoubleClick
        ? (e) => {
            e.preventDefault();
            record.id && onDriveItemDoubleClick(record, e);
          }
        : (e) => e.preventDefault(),
    };
  };

  const searchOnline = useCallback(() => {
    return { searchTerm: searchInput };
  }, [searchInput]);

  const getDriveItems = useCallback(() => {
    if (noResult) {
      return [];
    }
    if (searchOnline().searchTerm) {
      if (
        searchOnline &&
        searchOnline().searchTerm.trim() !== '' &&
        isKeywordSearch
      ) {
        return driveItemsFromSearch ?? [];
      }
    }
    return driveItems ?? [];
  }, [
    driveItemsFromSearch,
    driveItems,
    searchOnline,
    noResult,
    isKeywordSearch,
  ]);

  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    dispatch(
      fetchDriveItemsSagaAction(
        activeProjectId,
        groupId,
        currentFolderDriveItemId,
        250,
        isRoot,
        false,
        true
      )
    );
    setSelectedDriveItems([]);
  }, [dispatch, groupId, isRoot, currentFolderDriveItemId, activeProjectId]);

  useEffect(() => {
    setIsKeywordSearch(false);
    setNoResult(false);

    if (!!groupId) {
      if (searchInput) {
        const triggerOnlineSearch = async (
          searchTerm: string
        ): Promise<void> => {
          const projectIdFromSearch = searchTerm.match(
            /(?<=projects\/)(.*)(?=\/documents)/
          )?.[0];
          const driveItemsFolderIdFromSearch = searchTerm.match(
            /(?<=\/documents\/folder\/)(.*)(?=)/
          )?.[0];
          const isPrioLink = !!projectIdFromSearch;

          if (isPrioLink) {
            setProjectIdFromUrl(projectIdFromSearch);
            setSearchDriveItemId(
              driveItemsFolderIdFromSearch ?? rootDriveItemFolderId
            );
            if (projectsById[projectIdFromSearch]) {
              dispatch(
                fetchDriveItemsSagaAction(
                  activeProjectId,
                  projectsById[projectIdFromSearch].groupId,
                  driveItemsFolderIdFromSearch ?? rootDriveItemFolderId,
                  250,
                  driveItemsFolderIdFromSearch === 'all',
                  false,
                  true
                )
              );
            }
          } else {
            try {
              setIsKeywordSearch(true);
              const { data } = await apiFetchDocumentSearchResults(
                groupId,
                searchTerm.trim()
              );
              if (data?.children && Array.isArray(data.children)) {
                setDriveItemsFromSearch(data.children);
              } else {
                setNoResult(true);
              }
            } catch (error) {
              console.error(
                'Error while fetching document search results:',
                error
              );
            }
          }
        };

        const { searchTerm } = searchOnline();
        triggerOnlineSearch(searchTerm);
      } else {
        setDriveItemsFromSearch(null);
        setSearchDriveItemId(null);
      }
      setSelectedDriveItems([]);
    }
  }, [
    projectsById,
    activeProjectId,
    groupId,
    searchInput,
    dispatch,
    rootDriveItemFolderId,
    searchOnline,
    setIsKeywordSearch,
    setProjectIdFromUrl,
    setSearchDriveItemId,
    t,
  ]);

  const onUnzipArchive = useCallback(
    async (driveItem: DriveItem) => {
      if (driveItem) {
        await apiUnzipArchive(groupId, driveItem, currentFolderDriveItemId);
      }
    },
    [groupId, currentFolderDriveItemId]
  );
  //#endregion

  //#region -------------------------------- Components
  const noItemsErrorScreen = () => (
    <Flex.Column
      childrenGap={8}
      alignItems="center"
      justifyContent="center"
      flex={1}
    >
      <Flex.Item display="flex" width={40} height={40}>
        <FontAwesomeIcon
          icon={['fad', 'folder-xmark']}
          style={{
            width: '100%',
            height: '100%',
            //@ts-ignore
            '--fa-primary-color': theme.old.palette.chromaticPalette.red,
            '--fa-secondary-color': theme.old.palette.chromaticPalette.yellow,
            '--fa-primary-opacity': 1,
            '--fa-secondary-opacity': 1,
          }}
        />
      </Flex.Item>
      <Flex.Item>{errorMessage}</Flex.Item>
    </Flex.Column>
  );

  const noItemsScreen = () => (
    <div className={classes.noItemsScreen}>
      <div className={classes.noItemsIconContainer}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span className={classes.noItemsText}>
              {t('documents:table.noItems')}
            </span>
          }
        />
      </div>
    </div>
  );
  const onOpenMessage = useCallback(
    async (driveItem: DriveItem) => {
      const messageIds =
        driveItem.listItemFields?.Prio365AttachedMessageImmutableId;

      const { data } = await apiFetchSavedAttachmentMetadata(messageIds);

      if (data && data.length > 0) {
        const { projectId, messageImmutableId } = data[0];

        const width = window.screen.availWidth / 2;
        const height = window.screen.availHeight / 2;
        window.open(
          `/view/${
            projectId.length === 0 ? 'me' : projectId
          }/message/${messageImmutableId}/details`,
          '_blank',
          `width=${width},height=${height},noopener,noreferrer`
        );
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:errorMessages.messages.savedAttachmentMetadataError'
          ),
        });
      }
    },
    [t]
  );

  const menu = useCallback(
    (entry: DriveItem) => {
      const oneGigaByteInBytes = 1073741824;
      const isUnzippable = () => {
        const whenSearching = isKeywordSearch
          ? entry.parentReference.id === rootFolderDriveItem?.id
            ? false
            : true
          : !isRoot;
        return (
          entry?.file?.mimeType === 'application/zip' &&
          whenSearching &&
          entry.size / oneGigaByteInBytes <= 2
        );
      };

      return (
        <DocumentsTableContextMenu
          className={classes.menuButton}
          project={projectsById[activeProjectId]}
          iconClassname={classes.rowMenuButtonIcon}
          driveItem={entry}
          parentIsRoot={isRoot}
          onMenuRename={onMenuRename}
          onMenuCopy={onMenuCopy}
          onMenuMove={onMenuMove}
          onMenuDelete={onMenuDelete}
          onCopyAsPdf={onCopyAsPdf}
          onMenuDownload={onMenuDownload}
          onUnzipArchive={isUnzippable() ? onUnzipArchive : undefined}
          openParentFolder={onOpenParentFolder}
          openInBrowser={activeProjectId ? openInBrowser : undefined}
          openLocally={activeProjectId ? openLocally : undefined}
          isSearchingDocumentsInWidgetBar={isSearchingDocuments}
          onMenuEditFolderAccessRights={onEditFolderAccessRights}
          onDownloadXInvoice={onDownloadXInvoice}
        />
      );
    },
    [
      classes,
      activeProjectId,
      isRoot,
      onMenuDownload,
      onMenuDelete,
      onMenuRename,
      onMenuMove,
      onCopyAsPdf,
      onMenuCopy,
      openInBrowser,
      openLocally,
      onUnzipArchive,
      onOpenParentFolder,
      isSearchingDocuments,
      rootFolderDriveItem?.id,
      isKeywordSearch,
      onEditFolderAccessRights,
      projectsById,
      onDownloadXInvoice,
    ]
  );
  //#endregion

  //#region ------------------------------ ColumnProps
  const columns: VColumn<DriveItem>[] = useMemo(
    () => [
      {
        id: 'file',
        alignSelf: true,
        width: 15,
        title: t('documents:table.columnTitle.isFolder'),
        accessor: 'file',
        sortType: (rowA, rowB) => {
          return iconForFile(rowA.original).localeCompare(
            iconForFile(rowB.original)
          );
        },
        className: classes.columnFile,
        Cell: (cellProps) => (
          <DocumentsTableRowIcon
            classes={documentsTableRowIconClasses}
            driveItem={cellProps?.row?.original}
            groupId={groupId}
            projectId={activeProjectId}
            driveItemList={cellProps?.rows?.map((x) => x.original) ?? []}
          />
        ),
      },
      {
        id: 'name',
        alignSelf: true,
        title: t('documents:table.columnTitle.name'),
        accessor: 'name',
        innerStyle: {
          textOverflow: 'ellipsis',
        },
        width: 85,
        className: classes.columnName,
        Cell: (cellProps) => (
          <Flex.Row alignItems="center">
            <div
              style={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {cellProps.row.original.name}
            </div>
            {!!cellProps?.row?.original.listItemFields
              ?.Prio365AttachedMessageId?.[0] && (
              <div
                style={{
                  height: theme.old.components.documentsTable.tableRowHeight,
                }}
              >
                <Button
                  type="default"
                  onClick={() => {
                    onOpenMessage(cellProps?.row?.original);
                  }}
                >
                  <div className="fa-layers">
                    <FontAwesomeIcon
                      icon={['fas', 'circle']}
                      mask={['fal', 'envelope']}
                      className={classes.maskedIcon}
                    />
                    <FontAwesomeIcon
                      icon={['fal', 'link']}
                      style={{
                        transform: 'scale(.75) translate(.5em, .25em)',
                      }}
                    />
                  </div>
                </Button>
              </div>
            )}
            <div
              style={{
                height: theme.old.components.documentsTable.tableRowHeight,
              }}
            >
              {menu(cellProps.row.original)}
            </div>
          </Flex.Row>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.name.localeCompare(rowB.original.name);
        },
      },
    ],
    [
      t,
      classes,
      menu,
      groupId,
      theme.old.components.documentsTable.tableRowHeight,
      activeProjectId,
      onOpenMessage,
      documentsTableRowIconClasses,
    ]
  );
  //#endregion

  return (
    <div className={classNames(classes.root)}>
      <VirtualTable2
        className={classes.vTable}
        id={tableId}
        columnRowHeight={
          theme.old.components.documentsTable.tableColumnRowHeight
        }
        selected={(driveItem) =>
          selectedDriveItems.some((x) => x.id === driveItem.id)
        }
        columns={columns}
        data={getDriveItems()}
        rowsAreSelectable
        overscanRowCount={10}
        columnsResizable
        loading={getLoading()}
        noItemsScreen={errorMessage ? noItemsErrorScreen() : noItemsScreen()}
        onRow={{
          onRowDrag: {
            dragType: DND_TYPE_DRIVE_ITEM_FILE,
            configureDraggedObject: (
              draggedItem: DriveItem,
              selectedDriveItems: DriveItem[]
            ) => {
              return {
                driveItem: draggedItem,
                driveItems:
                  selectedDriveItems.length === 0 ||
                  !selectedDriveItems.find(
                    (selectedItem) => selectedItem.id === draggedItem.id
                  )
                    ? [draggedItem]
                    : selectedDriveItems,
                groupId: groupId,
                sourceFolderId: rootFolderDriveItem?.id ?? '',
                projectId: activeProjectId,
                root: {
                  sourceFolderRootId:
                    currentFolderDriveItemId ?? `root-group-${groupId}`,
                  sourceIsRoot: isRoot,
                },
              } as DndDriveItemDto;
            },
            onDragEnd: onDragEnd,
          },
          onRowDrop: {
            configureDroppableObject: (
              targetDropItem: DriveItem,
              selectedDriveItems: DriveItem[]
            ) =>
              ({
                projectId: activeProjectId,
                groupId: groupId,
                driveItem: targetDropItem,
              }) as FolderDriveItem,

            including: (x) => isDriveItemFolder(x),
            disable: (item, monitor, targetDropItem: FolderDriveItem) => {
              return false;
            },
            acceptType: [
              DND_TYPE_DRIVE_ITEM_FILE,
              DND_TYPE_EMAIL,
              DND_TYPE_EMAIL_ATTACHMENT,
            ],
            onDrop: onDrop,
            onOver: onOver,
          },
          triggerFunctions: triggerFunctions,
        }}
        onScroll={onScroll}
        onChange={onChange}
        dataToForceRender={dataToForceRender}
        classNameTableRow={rowClass}
        rowHeight={theme.old.components.documentsTable.tableRowHeight}
      />
    </div>
  );
};

export default DocumentsWidgetTable;
