import React, { useEffect, useState, useCallback } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import {
  apiFetchContactClassifications,
  apiUpdateContactClassification,
  apiDeleteContactClassification,
} from '../api';
import ContactClassificationDrawer from './ContactClassificationDrawer';
import { ContactClassificationDto } from '../../../models/ContactClassifications';
import ContactClassificationTable from './ContactClassificationTable';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  saveBar: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#f5f5f5',
    borderTop: '1px solid #ddd',
  },
}));

type ContactClassificationWithDirty = ContactClassificationDto & {
  isDirty?: boolean;
};

interface ContactClassificationPageProps {
  className?: string;
}

const ContactClassificationPage: React.FC<
  ContactClassificationPageProps
> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [contactClassifications, setContactClassifications] = useState<
    ContactClassificationWithDirty[]
  >([]);
  const [originalClassifications, setOriginalClassifications] = useState<
    ContactClassificationDto[]
  >([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Fetch data
  const fetchContactClassifications = useCallback(async () => {
    try {
      const { data } = await apiFetchContactClassifications();
      data.sort((a, b) => a.name.localeCompare(b.name));
      setContactClassifications(
        data.map((item) => ({ ...item, isDirty: false }))
      );
      setOriginalClassifications(data);
    } catch (error) {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.fetchError'),
      });
    }
  }, [t]);

  useEffect(() => {
    fetchContactClassifications();
  }, [fetchContactClassifications]);

  // Track changes
  const handleClassificationChange = (
    updatedClassifications: ContactClassificationDto[]
  ) => {
    const hasChanges = updatedClassifications.some((item, index) => {
      const original = originalClassifications[index];
      return JSON.stringify(item) !== JSON.stringify(original);
    });

    setContactClassifications(updatedClassifications);
    setIsSaveEnabled(hasChanges);
  };

  // Save Changes
  const handleSave = async () => {
    setIsSaving(true);
    const dirtyEntries = contactClassifications.filter((item) => item.isDirty);

    try {
      await Promise.all(
        dirtyEntries.map(async (item) => {
          const { result } = await apiUpdateContactClassification(item);
          if (result.status < 200 || result.status >= 300) {
            throw new Error();
          }
        })
      );

      fetchContactClassifications();
      setIsSaveEnabled(false);
    } catch {
      notification.error({
        message: t('common:error'),
        description: t('settings:errorMessages.updateError'),
      });
    }
    setIsSaving(false);
  };

  // Delete Classification
  const handleDeleteClassification = async (name: string) => {
    try {
      await apiDeleteContactClassification([name]);
      fetchContactClassifications();
    } catch {
      notification.error({
        message: t('common:error'),
        description: t('settings:errorMessages.deleteError'),
      });
    }
  };

  return (
    <div className={classes.root}>
      {/* Header Bar */}
      <div className={classes.headerBar}>
        <Button type="primary" onClick={() => setIsDrawerVisible(true)}>
          {t('common:add')}
        </Button>
      </div>

      {/* Table */}
      <div className={classes.tableContainer}>
        <ContactClassificationTable
          contactClassifications={contactClassifications}
          deleteContactClassification={handleDeleteClassification}
          setUpdatedClassifications={(updated) =>
            handleClassificationChange(updated)
          }
        />
      </div>

      {/* Save Bar */}
      <div className={classes.saveBar}>
        <Button
          type="primary"
          disabled={!isSaveEnabled || isSaving}
          onClick={handleSave}
        >
          {t('common:save')}
        </Button>
      </div>

      {/* Drawer */}
      <ContactClassificationDrawer
        visible={isDrawerVisible}
        closeDrawer={() => {
          setIsDrawerVisible(false);
          fetchContactClassifications();
        }}
      />
    </div>
  );
};

export default ContactClassificationPage;
