import React, { useState } from 'react';
import { Drawer, Button, Input } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { apiCreateProjectJobTitleSuggestion } from '../api';

interface JobTitleFormModel {
  name: string;
}

interface ProjectJobTitlesDrawerProps {
  visible: boolean;
  closeDrawer: VoidFunction;
}

const ProjectJobTitlesDrawer: React.FC<ProjectJobTitlesDrawerProps> = ({
  visible,
  closeDrawer,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [jobTitles, setJobTitles] = useState<JobTitleFormModel[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleAddJobTitle = async () => {
    const values = await form.validateFields();
    setJobTitles((prev) => [...prev, { name: values.name }]);
    form.resetFields();
  };

  const handleRemoveJobTitle = (index: number) => {
    setJobTitles((prev) => prev.filter((_, i) => i !== index));
  };

  // Submit Job Titles
  const handleSaveJobTitles = async () => {
    if (jobTitles.length === 0) {
      notification.warning({
        message: t('common:warning'),
        description: t('settings:jobTitles.form.noJobTitles'),
      });
      return;
    }

    setIsSaving(true);
    try {
      // add foreach jobTitles the missing attributes (true)  useInternalProjectJobTitle?: boolean; useExternalProjectJobTitle?: boolean;

      jobTitles.map((jobTitle) => {
        return {
          name: jobTitle.name,
          useInternalProjectJobTitle: true,
          useExternalProjectJobTitle: true,
        };
      });

      await apiCreateProjectJobTitleSuggestion(jobTitles);
      setJobTitles([]); // Reset list after save
      closeDrawer();
    } catch (error) {
      notification.error({
        message: t('common:error'),
        description: t('settings:jobTitles.form.errorMessage'),
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Drawer
      title={t('settings:jobTitles.drawer.title')}
      visible={visible}
      onClose={closeDrawer}
      footer={
        <Button
          type="primary"
          onClick={handleSaveJobTitles}
          disabled={jobTitles.length === 0 || isSaving}
        >
          {t('common:save')}
        </Button>
      }
    >
      <Form form={form} layout="inline" onFinish={handleAddJobTitle}>
        <Form.Item
          name="name"
          label={t('settings:jobTitles.form.label')}
          rules={[
            { required: true, message: t('settings:jobTitles.form.required') },
          ]}
        >
          <Input placeholder={t('settings:jobTitles.form.placeholder')} />
        </Form.Item>
        <Form.Item>
          <Button type="default" htmlType="submit">
            {t('common:add')}
          </Button>
        </Form.Item>
      </Form>

      <div style={{ marginTop: '16px' }}>
        {jobTitles.length > 0 ? (
          <ul>
            {jobTitles.map((jobTitle, index) => (
              <li
                key={index}
                style={{
                  marginBottom: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>{jobTitle.name}</span>
                <Button type="link" onClick={() => handleRemoveJobTitle(index)}>
                  {t('common:remove')}
                </Button>
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('settings:jobTitles.form.noEntries')}</p>
        )}
      </div>
    </Drawer>
  );
};

export default ProjectJobTitlesDrawer;
